<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card class="pa-2 pa-sm-4" color="#fafafa">
      <v-card-title class="pa-4 d-flex align-baseline text-subtitle-1 text-sm-h6">
        {{ item.id ? "タグ編集" : "タグ登録" }}
        <span class="px-4 red--text text-caption">*必須項目</span>
      </v-card-title>

      <!-- タグ名・ラベル名 -->
      <v-form ref="form" v-model="valid">
        <v-row class="mt-4 px-4" no-gutters>
          <v-col class="mb-4 mb-sm-0 pr-sm-2" cols="12" sm="6">
            <v-text-field
              v-model="tagName"
              :rules="[
                required,
                (value) => !items.some((e) => e.tagName == value && e.id != item.id) || 'すでに使用されています',
              ]"
              hint="タグを識別する名称です。重複不可"
              persistent-hint
              dense
            >
              <template #label>タグ名<span class="asterisk">*</span></template>
            </v-text-field>
          </v-col>
          <v-col class="mb-sm-0 pl-sm-2" cols="12" sm="6">
            <v-text-field v-model="label" :rules="[required]" hint="点検時に表示する名称です。" persistent-hint dense>
              <template #label>ラベル名<span class="asterisk">*</span></template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <!-- 入力タイプの選択 -->
      <v-row v-if="$vuetify.breakpoint.smAndUp" class="mt-8 mb-4" no-gutters align-content="start">
        <v-col class="px-4" cols="12"><p class="mb-1 text--primary">入力タイプ</p></v-col>
        <v-col cols="12"><v-divider /></v-col>
        <v-col class="pt-4 d-flex" cols="12" style="min-height: 230px">
          <div class="pl-4 pr-6 py-2">
            <v-radio-group class="ma-0 pa-0" v-model="type" mandatory hide-details dense>
              <v-radio v-for="(t, i) in types" :key="`radio_${i}`" :label="t.label" :value="t" />
            </v-radio-group>
          </div>
          <v-divider vertical />

          <div class="pl-6 pr-4 py-2" style="flex: 1">
            <InputEdit showType="tag" :type="type" />
          </div>
        </v-col>
      </v-row>

      <!-- 入力タイプの選択 縦スマホ時 -->
      <v-row v-else class="mt-8 mb-4" no-gutters>
        <v-col class="px-4" cols="12">
          <v-select
            :value="type.label"
            :items="types.map((t) => t.label)"
            dense
            @change="type = types.find((t) => t.label == $event)"
          >
            <template #label>入力タイプ<span class="asterisk">*</span></template>
          </v-select>
        </v-col>
        <v-col class="px-4 py-2" cols="12" style="min-height: 230px">
          <InputEdit showType="tag" :type="type" />
        </v-col>
      </v-row>

      <!-- 表示例 -->
      <v-row no-gutters align-content="start" style="min-height: 150px">
        <v-col class="px-4" cols="12"><p class="mb-1 text--primary">表示例</p></v-col>
        <v-col class="mb-4" cols="12"><v-divider /></v-col>
        <v-col
          class="px-4 d-flex text-body-2"
          :class="type.inputType == 'show' ? 'py-2' : type.inputType == 'text' ? 'flex-column' : 'align-center'"
          cols="12"
        >
          <span :class="type.inputType == 'text' ? 'pt-2 pb-1' : ''">
            {{ label ? label : "ラベル名" }}
          </span>
          <span v-if="type.inputType == 'show'">：</span>
          <v-spacer v-if="type.inputType != 'show' && type.inputType != 'text'" />
          <InputResult showType="tag" :inputType="type.inputType" :data="type" :value.sync="type.value" />
        </v-col>
        <v-col class="px-2" cols="12"><v-divider /></v-col>
      </v-row>

      <v-card-actions>
        <div class="text-caption text--secondary">
          <p class="mb-0" v-if="item.updatedAt">更新日：{{ formatDate(item.updatedAt, "YYYY/MM/DD") }}</p>
          <p class="mb-0" v-if="item.updaterName">更新者：{{ item.updaterName }}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn color="primary" depressed :loading="processing" :disabled="!valid || processing" @click="registItem()">
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "../../plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import { INPUT_TYPES } from "@/constants/inputTypes";
import InputEdit from "../organisms/InputEdit";
import InputResult from "../organisms/InputResult";

export default {
  components: {
    InputEdit,
    InputResult,
  },
  props: {
    items: Array,
    updateDB: Function,
  },
  mixins: [calcDate, dbProcess],
  data: () => ({
    dialog: false,
    valid: false,
    required: (v) => !!v || "必須項目です",
    item: {},
    tagName: "",
    label: "",
    type: {},
    types: [],
    processing: false,
  }),
  methods: {
    /**
     * ダイアログを開き、選択された行の情報を取得
     * @param {Object} item
     */
    async open(item) {
      this.item = {};
      this.type = {};

      // 入力タイプ一覧を取得
      const keys = ["show", "chipSelect", "text", "number", "date", "time", "image"];
      this.types = keys.map((key) => JSON.parse(JSON.stringify(INPUT_TYPES.find((t) => t.inputType == key))));
      this.type = this.types[0];

      // 編集の場合
      if (item) {
        this.item = JSON.parse(JSON.stringify(item));
        this.tagName = item.tagName;
        this.label = item.label;
        this.type = this.types.find((t) => t.inputType == item.type);
        for (const key of Object.keys(this.type)) {
          if (item[key] != undefined && key != "label") this.type[key] = this.item[key];
        }
      }

      this.dialog = true;
      if (!this.item.id) this.$nextTick(() => this.$refs.form.reset());
    },

    /**
     * 編集内容をDBに登録
     */
    async registItem() {
      this.processing = true;

      const shop = this.$store.getters.getShop;
      const user = this.$store.getters.getUser;
      const isCreate = !this.item.id;

      // 登録データ作成
      const registId = this.item.id || this.createDocId("productionReportCheckTags");
      const registData = {
        shopUID: shop.shopUID,
        tagName: this.tagName,
        label: this.label,
        type: this.type.inputType,
        updatedAt: new Date(),
        updaterName: user.name,
      };

      // 入力タイプ別に設定追加
      const registKeys = INPUT_TYPES.find((t) => t.inputType == this.type.inputType).registKeys;
      for (const key of Object.keys(this.type)) if (registKeys.includes(key)) registData[key] = this.type[key];

      if (isCreate) registData.createdAt = new Date();

      const isSuccess = await this.updateDB({
        method: isCreate ? "set" : "update",
        collection: "productionReportCheckTags",
        docId: registId,
        data: registData,
      });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>

<style scoped>
.asterisk {
  margin-left: 2px;
  color: red;
  font-weight: 700;
}

::v-deep .v-label {
  font-size: 14px;
}

::v-deep .v-radio .v-label {
  color: #000000de;
}
</style>
