<template>
  <v-dialog v-model="dialog" persistent max-width="1000">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4 d-flex align-baseline">
        <span class="text-subtitle-1 text-sm-h6">{{ item.id ? "帳票編集" : "帳票登録" }}</span>
        <span class="px-4 red--text text-caption">*必須項目</span>
      </v-card-title>

      <v-card-text class="px-4 pb-2">
        <v-form ref="form" v-model="valid">
          <v-row no-gutters>
            <v-col cols="10" sm="9">
              <v-text-field v-model="item.sheetName" :rules="[required, duplicated]">
                <template #label>帳票名<span class="asterisk">*</span></template>
              </v-text-field>
            </v-col>
            <!-- 既存帳票のコピー機能 -->
            <v-col class="px-2 px-sm-4" align-self="center">
              <MenuSheetCopy :items="settings[0].items" @select="copyItem" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-tabs v-model="tab" show-arrows background-color="transparent">
        <v-tab>
          <v-icon class="mr-1" small>mdi-tag-outline</v-icon>タグ（{{ tags.filter((e) => !!e.id).length }}）
        </v-tab>
        <v-tab>
          <v-icon class="mr-1" small>mdi-format-list-numbered</v-icon>点検項目（{{
            contents.filter((e) => !!e.id).length
          }}）
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="mb-4 transparent">
        <v-tab-item v-for="(tabItem, tabIndex) in tabItems" :key="tabIndex">
          <div class="d-flex flex-column flex-sm-row">
            <v-card :width="$vuetify.breakpoint.xs ? '100%' : '50%'">
              <v-data-table
                :headers="[
                  { text: tabItem.itemText, value: tabItem.itemKey },
                  { text: '', value: 'action', align: 'end', class: 'pr-1', width: 96 },
                ]"
                :items="$data[tabItem.key]"
                :items-per-page="-1"
                disable-sort
                fixed-header
                hide-default-footer
                :mobile-breakpoint="null"
                :height="tableHeight"
                dense
              >
                <template #body="{ items }">
                  <tr v-if="items.length == 0">
                    <td class="py-2 text-center text--secondary" colspan="2">データがありません</td>
                  </tr>
                  <draggable v-else v-model="$data[tabItem.key]" tag="tbody" handle=".drag" animation="200">
                    <tr v-for="(item, i) in items" :key="i">
                      <td>
                        <v-divider
                          v-if="item.type == 'line'"
                          :style="{
                            margin: '0 5%',
                            'border-color': '#aaa',
                            'border-width': '1px',
                            'border-style': item.borderStyle,
                          }"
                        />
                        <span v-else>{{ item[tabItem.itemKey] }}</span>
                      </td>
                      <td class="pr-2">
                        <v-btn icon @click.stop="$data[tabItem.key].splice(i, 1)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                        <v-btn class="ml-2 drag" icon small style="cursor: move">
                          <v-icon>mdi-drag-horizontal-variant</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>
            </v-card>

            <div class="pa-2" />

            <v-card :width="$vuetify.breakpoint.xs ? '100%' : '50%'">
              <v-data-table
                :headers="[
                  { text: '区切り線', value: 'name', width: 100 },
                  { text: '', value: 'line' },
                  { text: '', value: 'action', width: 56 },
                ]"
                :items="[
                  { name: '実線', line: 'solid' },
                  { name: '点線', line: 'dashed' },
                  { name: '空欄', line: 'none' },
                ]"
                disable-sort
                fixed-header
                hide-default-footer
                :mobile-breakpoint="null"
                dense
                style="margin-bottom: 27px"
              >
                <template #[`item.line`]="{ item }">
                  <v-divider
                    :style="{
                      'border-color': '#aaa',
                      'border-width': '1px',
                      'border-style': item.line,
                    }"
                  />
                </template>
                <template #[`item.action`]="{ item }">
                  <v-btn
                    icon
                    @click="
                      $data[tabItems[tab].key].push({
                        [tabItems[tab].itemKey]: '',
                        type: 'line',
                        borderStyle: item.line,
                      })
                    "
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-data-table
                :headers="[
                  { text: tabItem.masterText, value: tabItem.itemKey },
                  { text: '利用帳票数', value: 'useSheetsCount', align: 'end', width: 94 },
                  { text: '', value: 'action', width: '56px' },
                ]"
                :items="settings[tabIndex + 1].items"
                :items-per-page="-1"
                :search="search[tabItem.itemKey]"
                no-data-text="データがありません"
                no-results-text="データが見つかりません"
                disable-sort
                fixed-header
                hide-default-footer
                :mobile-breakpoint="null"
                height="40vh"
                dense
              >
                <template #[`header.${tabItem.itemKey}`]="">
                  <v-text-field
                    class="text-body-2 font-weight-regular"
                    v-model="search[tabItem.itemKey]"
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="tabItem.masterText"
                    clearable
                    hide-details
                    dense
                  />
                </template>
                <template #[`item.useSheetsCount`]="{ item }">
                  <MenuUseSheets :title="`${tabItem.itemText}：${item[tabItem.itemKey]}`" :items="item.useSheets" />
                </template>
                <template #[`item.action`]="{ item }">
                  <v-btn icon @click="$data[tabItems[tab].key].push(item)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs-items>

      <v-card-actions>
        <div class="text-caption text--secondary">
          <p class="mb-0" v-if="updatedAt">更新日：{{ updatedAt }}</p>
          <p class="mb-0" v-if="item.updaterName">更新者：{{ item.updaterName }}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn
          color="primary"
          depressed
          :loading="processing"
          :disabled="!valid || contents.length === 0 || processing"
          @click="registItem()"
        >
          登録
        </v-btn>
      </v-card-actions>
    </v-card>

    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-dialog>
</template>

<script>
import { db } from "@/plugins/firebase";
import moment from "moment";
import Draggable from "vuedraggable";
import MenuSheetCopy from "../organisms/MenuSheetCopy";
import MenuUseSheets from "../organisms/MenuUseSheets";

export default {
  components: {
    Draggable,
    MenuSheetCopy,
    MenuUseSheets,
  },
  props: {
    settings: Array,
    updateDB: Function,
  },
  data: () => ({
    dialog: false,
    valid: false,
    required: (v) => !!v || "必須項目です",
    item: {},
    tab: 0,
    tabItems: [
      { key: "tags", itemText: "タグ名", itemKey: "tagName", masterText: "設定可能なタグ" },
      { key: "contents", itemText: "点検内容", itemKey: "content", masterText: "設定可能な点検項目" },
    ],
    tags: [],
    contents: [],
    search: { tagName: "", content: "" },
    updatedAt: "",
    processing: false,
    message: "",
    messageDialog: false,
  }),
  computed: {
    duplicated() {
      return (value) =>
        !this.settings[0].items.some((e) => e.sheetName == value && e.id != this.item.id) || "すでに使用されています";
    },

    /**
     * テーブルの高さ取得
     * @return {number}
     */
    tableHeight() {
      const displayHeight = this.$vuetify.breakpoint.height;
      return displayHeight * 0.4 + 185;
    },
  },
  methods: {
    /**
     * ダイアログを開き、選択された行の情報を取得
     * @param {item} item
     */
    async open(item) {
      this.item = item ? JSON.parse(JSON.stringify(item)) : { tagUID: [], contentUID: [] };
      this.updatedAt = item ? moment(item.updatedAt.seconds * 1000).format("YYYY/MM/DD") : "";
      this.tags = [];
      this.contents = [];

      for (const uid of this.item.tagUID) {
        const tag =
          uid.length == 20
            ? this.settings[1].items.find((e) => e.id == uid)
            : { tagName: "", type: "line", borderStyle: uid };
        if (tag) this.tags.push(tag);
      }

      for (const uid of this.item.contentUID) {
        const content =
          uid.length == 20
            ? this.settings[2].items.find((e) => e.id == uid)
            : { content: "", type: "line", borderStyle: uid };
        if (content) this.contents.push(content);
      }

      this.dialog = true;
      if (!item) await this.$nextTick(() => this.$refs.form.reset());
    },

    /**
     * 登録済帳票のコピー
     * @param {object} item 選択した帳票情報
     */
    copyItem(item) {
      this.item = {
        id: this.item.id,
        sheetName: `${item.sheetName} - コピー`,
        tagUID: item.tagUID,
        contentUID: item.contentUID,
        updaterName: this.item.updaterName,
      };
      this.tags = [];
      this.contents = [];

      for (const uid of this.item.tagUID) {
        const tag =
          uid.length == 20
            ? this.settings[1].items.find((e) => e.id == uid)
            : { tagName: "", type: "line", borderStyle: uid };
        if (tag) this.tags.push(tag);
      }

      for (const uid of this.item.contentUID) {
        const content =
          uid.length == 20
            ? this.settings[2].items.find((e) => e.id == uid)
            : { content: "", type: "line", borderStyle: uid };
        if (content) this.contents.push(content);
      }
    },

    /**
     * 帳票登録
     */
    async registItem() {
      this.processing = true;

      const shop = this.$store.getters.getShop;
      const user = this.$store.getters.getUser;
      const isCreate = !this.item.id;

      // 登録データ作成
      const registId = this.item.id || db.collection("productionReportCheckSheets").doc().id;
      const registData = {
        shopUID: shop.shopUID,
        sheetName: this.item.sheetName,
        tagUID: this.tags.map((e) => e.id || e.borderStyle),
        contentUID: this.contents.map((e) => e.id || e.borderStyle),
        updatedAt: new Date(),
        updaterName: user.name,
      };

      if (isCreate) registData.createdAt = new Date();

      const isSuccess = await this.updateDB({
        method: isCreate ? "set" : "update",
        collection: "productionReportCheckSheets",
        docId: registId,
        data: registData,
      });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>

<style scoped>
.asterisk {
  margin-left: 2px;
  color: red;
  font-weight: 700;
}

::v-deep .v-tabs-bar {
  height: 38px;
  border-bottom: solid 1px #0000001f !important;
}

.v-window {
  overflow: initial;
}

::v-deep .v-data-table .v-data-table__wrapper table thead tr th {
  height: 48px;
  background-color: #fafafa;
}

::v-deep .v-list-item {
  min-height: 36px !important;
}

::v-deep .v-list-item__content {
  padding: 0;
}

::v-deep .v-list-item__title {
  font-size: 0.8rem;
}
</style>
