var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-2 pa-sm-4",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-4",staticStyle:{"height":"60px"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4","align-self":"center"}},[_c('span',{staticClass:"text-subtitle-1 text-sm-h6"},[_vm._v("帳票設定")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-tabs',{attrs:{"show-arrows":"","background-color":"#f5f5f5"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.settings),function(setting,i){return _c('v-tab',{key:i},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(setting.icon))]),_vm._v(" "+_vm._s(setting.name)+" ")],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.settings),function(setting,i){return _c('v-tab-item',{key:("tab_" + i)},[_c('v-card',{attrs:{"height":_vm.getCardHeight,"color":"#fafafa"}},[_c('v-card-title',{staticClass:"d-flex text-body-1"},[_vm._v(" "+_vm._s(((_vm.settings[_vm.tab].name) + "一覧"))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"white",attrs:{"color":"primary","icon":"","elevation":"1"},on:{"click":function($event){_vm.$refs[_vm.settings[_vm.tab].ref].open()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-data-table',{class:_vm.$vuetify.breakpoint.xs ? 'table--xs' : '',attrs:{"headers":setting.headers,"items":setting.items,"items-per-page":-1,"loading":_vm.loading,"loading-text":"読込中","no-data-text":"データがありません","disable-sort":_vm.$vuetify.breakpoint.xs,"fixed-header":"","hide-default-footer":"","height":_vm.getCardHeight - 68,"dense":""},scopedSlots:_vm._u([{key:"item.tagCount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.tagUID.length))]}},{key:"item.contentCount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.contentUID.length))]}},{key:"item.allowableMin",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.allowableMin)+"℃")]}},{key:"item.allowableMax",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.allowableMax)+"℃")]}},{key:"item.referable",fn:function(ref){
var item = ref.item;
return [(item.referable)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.required",fn:function(ref){
var item = ref.item;
return [(item.required)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.inputType[item.type]))]),(item.type == 'number')?_c('span',{staticClass:"ml-2 text-caption text--secondary"},[_vm._v("単位："+_vm._s(item.unit ? item.unit : "なし")+" ")]):_vm._e(),(item.type == 'time')?_c('span',{staticClass:"text-caption text--secondary"},[_c('br'),_vm._v("形式："+_vm._s(item.timeFormat)+" ")]):_vm._e()]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.formatDate(item.updatedAt, "YYYY/MM/DD HH:mm")))])]}},{key:"item.useSheetsCount",fn:function(ref){
var item = ref.item;
return [_c('MenuUseSheets',{attrs:{"title":((setting.headers[0].text) + "：" + (item[setting.headers[0].value])),"items":item.useSheets}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.$refs[setting.ref].open(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.confirmDialog.open({
                      docId: item.id,
                      collection: setting.collection,
                      title: ("下記" + (setting.name) + "を削除します"),
                      text: ((setting.name) + "名：" + (item[setting.order])),
                    })}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})],1)],1)}),1)],1)],1),_c('DialogEditSheet',{ref:"editSheetDialog",attrs:{"settings":_vm.settings,"updateDB":_vm.updateDB}}),_c('DialogEditTag',{ref:"editTagDialog",attrs:{"items":_vm.settings.find(function (s) { return s.name == 'タグ'; }).items,"updateDB":_vm.updateDB}}),_c('DialogEditContent',{ref:"editContentDialog",attrs:{"items":_vm.settings.find(function (s) { return s.name == '点検項目'; }).items,"updateDB":_vm.updateDB}}),_c('DialogConfirm',{ref:"confirmDialog",attrs:{"method":_vm.updateDB}}),_c('DialogSendError',{ref:"sendErrorDialog"}),_c('DialogMessage',{attrs:{"dialog":_vm.messageDialog,"message":_vm.message},on:{"close":function($event){_vm.messageDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }